import React from "react"
import { graphql } from "gatsby";
import {Grid, Typography, CardMedia, Card, CardContent} from '@material-ui/core'
import Image from 'react-bootstrap/Image'
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { makeStyles} from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const useStyles = makeStyles(theme => ({
      title:{
          fontSize: '2em',
          paddingTop: 10,
          paddingBottom: 20,
      },
      banner: {
          fontWeight: 'bold',
          paddingTop: 20
      },
      banner1:{
        fontSize: '1.5em',
        paddingTop: 20,
        paddingBottom:20,
        color: 'white',
        textAlign: 'center'
      },
      bannerBackground:{
          backgroundColor: '#29333D'
      },
      requirement: {
          paddingLeft: 30,
          paddingTop: 5,
      },
      text:{
        //paddingTop: 10,
        paddingBottom: 15,
      },
      bold:{
        fontWeight: 'bold',
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
      spacing:{
        padding: 5
      },
    }))

const CapEnter = ({ data }) => {
   /*  const data= useStaticQuery(graphql`query capacitacionempresas {
        ceformaPagesShow10 {
          data {
            page {
              content {
                Imgs {
                  ImgDescripcion
                  ImgUrl
                }
                ImportantPoints {
                  Bold
                  Normal
                }
                Topics {
                  Topic
                }
                banner1
                banner2
                title
                welcomeText
              }
            }
          }
        }
      }
      `) */
    const classes = useStyles();
    const page = data.ceformaPagesShow10.data.page.content;
    const getColor =(officeName)=>
    {
        switch(officeName){
            case 0: return ('#e2755f')
            case 1: return('#307ecc')
            case 2: return('#5a5a5a')
            case 3: return('#e8b10d')
            case 4: return ('#e2755f')
            case 5: return('#307ecc')
            case 6: return('#5a5a5a')
            case 7: return('#e8b10d')
            default: return ('red')
        }
    }
return(    
  <Layout>
    <SEO title={page.title}/>
    <Typography className={classes.title}>{page.title}</Typography>
    <Grid
    container
    direction="row" 
    justify="center"
    alignItems="flex-start">
        <Grid>
          <Typography className={classes.text}>
            {page.welcomeText}
          </Typography>
          <Grid  container
            direction="column"
            className={classes.text}>
          {
              page.Topics.map(topic=>(
                  <Typography><ArrowForwardIosIcon fontSize="small"/>{topic.Topic}</Typography>
              ))
          }
          </Grid>
          {
            page.ImportantPoints.map(points=>(
            <Grid  container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.text}>
                <Typography className={classes.bold}>{points.Bold +"\u00A0"}</Typography><Typography>{points.Normal}</Typography>
            </Grid>
            ))
          }
        </Grid>
    </Grid>
    <Grid
    container
    justify="center"
    alignItems="center">
        <Typography className={classes.banner}>{page.banner1}</Typography>
    </Grid>
    <Grid
    container
    justify="center"
    alignItems="center"
    style={{marginBottom:10,marginTop:10}}
    >
        {
            page.Imgs.map((img, index) =>(
                <Grid  iteam  xs={12} sm={6} md={4} lg={3} xl={3}>
                <Card className={classes.spacing} >
                    <CardContent style={{backgroundColor:getColor(index)}}><Typography>{img.ImgDescripcion}</Typography></CardContent>
                    <CardMedia className={classes.media} image={img.ImgUrl} title={img.ImgDescripcion}/>
                </Card>
                </Grid>
            ))
        }
    </Grid>
    <Grid
    container
    justify="center"
    alignItems="center"
    className={classes.bannerBackground}>
        <Typography className={classes.banner1}>{page.banner2}</Typography>
    </Grid>
  </Layout>
)}

export default CapEnter

export const query = graphql`query capacitacionempresas {
    ceformaPagesShow10 {
      data {
        page {
          content {
            Imgs {
              ImgDescripcion
              ImgUrl
            }
            ImportantPoints {
              Bold
              Normal
            }
            Topics {
              Topic
            }
            banner1
            banner2
            title
            welcomeText
          }
        }
      }
    }
  }
  `
